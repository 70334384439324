<template>
    <div class="pulse-spinner"></div>
</template>

<style lang="scss">

.pulse-spinner {
    width: auto;
    display: block;
    min-height: min-content;
    min-width: min-content;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    position: relative;
  }
  
  .pulse-spinner:before, .pulse-spinner:after {
    content: '';
    border: 2px solid;
    border-color: $cph-dark-blue;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    right: -25px;
    position: absolute;
  }

  body.th-dark .pulse-spinner:before, body.th-dark .pulse-spinner:after {
    border-color: hsl(150, 100%, 14%);
  }
  
  .pulse-spinner:before {
    -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
    -webkit-animation: spWaveBe 0.6s infinite linear;
            animation: spWaveBe 0.6s infinite linear;
  }
  
  .pulse-spinner:after {
    -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0;
    -webkit-animation: spWaveAf 0.6s infinite linear;
            animation: spWaveAf 0.6s infinite linear;
  }
  
  @-webkit-keyframes spWaveAf {
    from {
      -webkit-transform: scale(0.5, 0.5);
              transform: scale(0.5, 0.5);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes spWaveAf {
    from {
      -webkit-transform: scale(0.5, 0.5);
              transform: scale(0.5, 0.5);
      -webkit-transform: scale(0.5, 0.5);
              transform: scale(0.5, 0.5);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 1;
    }
  }
  @-webkit-keyframes spWaveBe {
    from {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5, 1.5);
              transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }
  @keyframes spWaveBe {
    from {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5, 1.5);
              transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }
</style>